































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Note, UpdateNote } from '../types';
import { UPDATE_NOTE } from '../store';
import { ApiResponse } from '@/components/types';
import WysiwygEditor from "@/components/WysiwygEditor.vue";

const Notes = namespace('notes');

@Component({
  components: { WysiwygEditor }
})
export default class UpdateNoteDialog extends Vue {
  @Prop({ type: Object }) value!: Note | null;

  @Notes.Action(UPDATE_NOTE) update!: (command: UpdateNote) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;

  config: object = { height: 500 }

  note: UpdateNote = {
    note_id: '',
    content: ''
  };

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value) return;

    setTimeout(() => {
      this.clear();
    }, 200);
  }

  @Watch('value')
  watchFile(file: File) {
    if (!file) return;

    this.init();

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  clear() {
    this.note = {
      note_id: '',
      content: ''
    };

    this.error = null;
    this.$emit('input', null);
    // @ts-ignore
    this.$refs.form.reset();
  }

  init() {
    if (!this.value) return;

    this.note = {
      note_id: this.value.id,
      content: this.value.content
    };
  }

  onSuccess() {
    this.dialog = false;
    this.$emit('success');
  }

  async save(): Promise<ApiResponse> {
    this.error = null;

    return this.update(this.note);
  }
}
