
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DeleteNote, Note } from '../types';
import { DELETE_NOTE } from '@/modules/notes/store';
import { ApiResponse } from '@/components/types';

const Notes = namespace('notes');

@Component({})
export default class RemoveFolderDialog extends Vue {
  @Prop({ type: Object }) value!: Note | null;

  @Notes.Action(DELETE_NOTE) remove!: (command: DeleteNote) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;

  note: DeleteNote = {
    note_id: '',
  };

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value) return;

    setTimeout(() => {
      this.clear();
    }, 200);
  }

  @Watch('value')
  watchFile(note: Note) {
    if (!note) return;

    this.init();

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  clear() {
    this.note = {
      note_id: ''
    };

    this.error = null;
    this.$emit('input', null);
  }

  init() {
    if (!this.value) return;

    this.note = {
      note_id: this.value.id
    };
  }

  onSuccess() {
    this.dialog = false;
    this.$emit('success');
  }

  async save(): Promise<ApiResponse> {
    this.error = null;

    return this.remove(this.note);
  }
}
