






















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Pagination } from '@/components/types';

@Component({ inheritAttrs: false })
export default class AppIterator extends Vue {
  @Prop({ type: Object, default: () => ({}) }) pagination!: Pagination;
  @Prop({ type: String, default: 'Keine Daten gefunden' }) noResults!: string;
  @Prop({ type: String, default: 'Keine Daten vorhanden' }) noData!: string;
  @Prop({ type: String, default: 'Daten werden geladen' }) loadingText!: string;
  @Prop({ type: Boolean, default: false }) loading!: boolean;

  options: Pagination = {};

  @Watch('options', { deep: true })
  onOptionsChange(pagination: Pagination) {
    if (JSON.stringify(pagination) === JSON.stringify(this.pagination)) return;

    this.$emit('update:pagination', pagination);
  }

  @Watch('pagination', { immediate: true, deep: true })
  onPaginationChange(pagination: Pagination) {
    this.options = { ...pagination };
  }

  onCount(counter: number) {
    this.options.pageCount = counter;
  }
}
