






















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import { CREATE_NOTE } from '@/modules/notes/store';
import { CreateNote } from '@/modules/notes/types';
import { createNote } from '@/modules/notes/model';
import WysiwygEditor from '@/components/WysiwygEditor.vue';

const Notes = namespace('notes');

@Component({ components: { WysiwygEditor } })
export default class CreateFolderDialog extends Vue {
  @Notes.Action(CREATE_NOTE) createNote!: (command: CreateNote) => Promise<ApiResponse>;

  @Prop({ type: String, required: true }) conservatorship!: string;

  error: any = null;
  loading: boolean = false;
  dialog: boolean = false;

  config: object = { height: 500 }

  note: CreateNote = createNote(this.conservatorship);

  clear(observer?: any) {
    this.dialog = false;

    setTimeout(() => {
      observer && observer.reset();
      this.note = createNote(this.conservatorship);
      this.error = null;
    }, 200);
  }

  success() {
    this.clear();

    this.$emit('success')
  }

  save() {
    this.error = null;

    return this.createNote(this.note);
  }
}
