






















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_LIST, GET_LIST } from '../store';
import { ApiResponse, Pagination } from '@/components/types';
import { Note } from '../types';
import CardRow from '@/components/CardRow.vue';
import AppIterator from '@/components/AppIterator.vue';
import CardHeader from '@/components/CardHeader.vue';
import CreateNoteDialog from "@/modules/notes/components/CreateNoteDialog.vue";
import UpdateNoteDialog from "@/modules/notes/components/UpdateNoteDialog.vue";
import DeleteNoteDialog from "@/modules/notes/components/DeleteNoteDialog.vue";
import DownloadAction from "@/modules/notes/components/DownloadAction.vue";

const Notes = namespace('notes');

@Component({
  components: {
    DownloadAction,
    DeleteNoteDialog,
    UpdateNoteDialog,
    CreateNoteDialog,
    CardHeader,
    AppIterator,
    CardRow
  }
})
export default class List extends Vue {
  @Prop({ type: String }) conservatorship?: string;

  @Notes.Getter(GET_LIST) lists!: { [conservatorship: string]: Note[] };
  @Notes.Action(FETCH_LIST) fetch!: (conservatorship: string) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;

  pagination: Pagination = {
    sortBy: ['created'],
    itemsPerPage: -1,
    descending: true
  };

  updateNote: Note | null = null;
  deleteNote: Note | null = null;

  get items(): Note[] {
    if (!this.conservatorship) return []

    return this.lists[this.conservatorship] || []
  }

  @Watch('conservatorship', { immediate: true })
  async fetchList(conservatorship: string) {
    if (!conservatorship) return;

    try {
      this.loading = true;

      await this.fetch(conservatorship);
    } catch (error) {
      this.error = error;
    }

    this.loading = false;
  }
}
