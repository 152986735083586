













import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import { FETCH_PDF } from "@/modules/notes/store";
import { Note } from "@/modules/notes/types";

const Notes = namespace('notes');

@Component({})
export default class DownloadAction extends Vue {
  @Prop({ type: String, default: 'btn-info' }) color!: string;
  @Prop({ type: Boolean, default: false }) large!: boolean;
  @Prop({ type: Boolean, default: true }) icon!: boolean;
  @Prop({ type: String }) label?: string;
  @Prop({ type: Object }) note!: Note;

  @Notes.Action(FETCH_PDF) fetchPDF!: (noteId: string) => Promise<ApiResponse>;

  loading: boolean = false;
  error: any = null;

  get filename(): string {
    return `notiz.pdf`;
  }

  async download() {
    this.error = null;
    this.loading = true;

    const { error, content } = await this.fetchPDF(this.note.id);

    if (error) {
      this.error = error;
      this.loading = false;
      return;
    }

    this.loading = false;

    const link = document.createElement('a');
    link.href = URL.createObjectURL(content);
    link.setAttribute('download', this.filename);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
